<template>
  <va-card v-if="showGrid" title="License Request">
    <template slot="actions">
      <va-button color="success" icon="fa fa-plus" @click="showGrid = false">Add</va-button>
      <va-button color="orange" v-if="isEnable" icon="entypo entypo-export" @click="exportData()">Export</va-button>
    </template>

    <div class="row align--center">
      <div class="flex xs12 md6">
        <va-input
          :value="term"
          placeholder="search"
          @input="search"
          removable>
          <va-icon name="fa fa-search" slot="prepend" />
        </va-input>
      </div>
    </div>
    <va-data-table
      :fields="fields"
      :data="filteredData"
      :per-page="parseInt(perPage)">
      <template slot="customer_org" slot-scope="props">
        <a @click="toStRoute(props.rowData)"
        class="link"
        >{{props.rowData.customer_name}}</a>
      </template>

      <template slot="approve" slot-scope="props">
        <va-button small 
        v-if="props.rowData.status == 'INITIATED' && getRole() == 'SYS_ADMIN'"
        color="success" icon="entypo entypo-thumbs-up" 
        @click="statusUpdate('APPROVED',props.rowData)" 
        class="ma-0"> Approve
        </va-button>
      </template>

      <template slot="decline" slot-scope="props">
        <va-button small 
        v-if="props.rowData.status == 'INITIATED' && getRole() == 'SYS_ADMIN'"
        color="danger" icon="entypo entypo-thumbs-down" 
        @click="statusUpdate('DECLINED',props.rowData)" 
        class="ma-0"> Decline
        </va-button>
      </template>

      <template slot="edit" slot-scope="props">
        <va-button flat small 
        v-if="props.rowData.status == 'INITIATED'"
        color="gray" icon="fa fa-pencil" 
        @click="editLicense(props.rowData)" 
        class="ma-0"/>
      </template>
    </va-data-table>
  </va-card>
  <va-card v-else :title="isCreate?'create license request':'update license request'">
    <template slot="actions">
      <va-button icon="fa fa-list"
        @click="resetValue()" color="gray">List</va-button>
    </template>
    <form @submit.prevent="isCreate?addPayment():updatePayment()">
      <va-select
        :label="getLabel(license_request.customer_org_id)"
        v-model="license_request.customer_org_id.value"
        :options="license_request.customer_org_id.options"
        :placeholder="license_request.customer_org_id.placeholder"
        :required="license_request.customer_org_id.required"
        textBy="customer_org_name"
        @input="getPlatform(license_request.customer_org_id.value)"
        noClear
      />      
      <va-input
        :label="getLabel(license_request.amount_paid)"
        v-model="license_request.amount_paid.value"
        :placeholder="license_request.amount_paid.placeholder"
        :required="license_request.amount_paid.required"
        :type="license_request.amount_paid.type"
      />      
      <va-input
        :label="getLabel(license_request.reference_number)"
        v-model="license_request.reference_number.value"
        :placeholder="license_request.reference_number.placeholder"
        :required="license_request.reference_number.required"
        :type="license_request.reference_number.type"
        pattern="^[A-Za-z0-9_-]+(?: +[A-Za-z0-9_-]+)*$"
      />
      <va-select
        :label="getLabel(license_request.platform)"
        searchable
        :placeholder="license_request.platform.placeholder"
        v-model="license_request.platform.value"
        v-if="plat_flag"
        :options="license_request.platform.options"
        :required="license_request.platform.required"
        textBy="platform_name"
        multiple
        noClear
      />     
      <label class="comment">{{getLabel(license_request.comment)}}</label><br>
      <textarea 
      :name="license_request.comment.name" 
      v-model="license_request.comment.value"
      rows="5" />
      <div class="d-flex justify--center mt-3">
        <va-button class="my-0" flat @click="resetValue()">Cancel</va-button>
        <va-button type="submit" class="my-0">{{isCreate?'Create':'Update'}}</va-button>
      </div>
    </form>
  </va-card>
</template>

<script>
import { debounce } from 'lodash'
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { exportToFile } from '../../i18n/exportFile.js'

Vue.use(vueResource)
export default {
  created(){
    this.getPayments()
    this.getAllCustomer()
  },
  data () {
    return {
      term:null,
      perPage:'10',
      isEnable : false,
      perPageOptions:['4', '6', '10', '20'],
      showGrid:true,
      isCreate:true,
      payment_id:'',
      license_requests: [],
      license_request:{
        customer_org_id: {
          placeholder:'Select Customer Organization',
          name: 'customer organization',
          value: '',
          required: true,
          type: 'select',
          options: [],
        },
        amount_paid: {
          placeholder:'Enter Amount',
          name: 'amount paid',
          value: '',
          required: true,
          type: 'number',
          min:0,
        },
        reference_number: {
          placeholder:'Enter Reference Number',
          name: 'reference number',
          value: '',
          required: true,
          type: 'text',
        },
        platform: {
          placeholder:'Select Platform',
          name: 'platform',
          value: [],
          required: true,
          type: 'select',
          options: [],
        },
        comment: {
          name: 'comment',
          value: '',
          required: false,
        }
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          name: 'sno',
          width: "8%"
        },
        {
          name: '__slot:customer_org',
          title: 'customer organization',
          width: "20%"
        },  
        {
          name: 'amount_paid',
          title: 'amount paid',
          width: "20%"
        },
        {
          name: 'reference_number',
          title: 'reference number',
          width: "30%"
        },        
        {
          name: 'status',
          title: 'status',
          width: "30%"
        },
        {
          name: '__slot:approve',
          width: "20%"
        },        
        {
          name: '__slot:decline',
          width: "20%"
        },        
        {
          name: '__slot:edit',
          width: "20%"
        }
      ]
    },
    filteredData () {
      let filteredLR = search(this.term, this.license_requests)
      return filteredLR
    }
  },
  methods:{
    getPlatform(data){
      this.plat_flag = true
      this.$http.get(config.menu.host+'customer_org_pt/'+data.customer_org_id).then(function(res){
        var arr = []
        res.body.map(function(item,i){
          if((item.license_status != 'ACTIVE') && (item.license_status != 'APPROVED')){
            item['id']=i+1
            var transcoderName;
            if(item.platform_name == 'TRANSCODER'){
              item.customer_org_pt_kycs.map(function(arg){
                if(arg.kyc_key == 'transcoder_name'){
                  transcoderName = arg.kyc_value
                  item.platform_name = item.platform_name+"-"+transcoderName
                }
              })
            }
            arr.push({'id':item.id,'platform_name':item.platform_name,'license_status':item.license_status,'platform_id':item.platform_id,"license_id":item.license_id,"transcoder_name":transcoderName})
          }
        })
        this.license_request.platform.options = arr
      })
    },
    toStRoute(data){
      this.$router.push(`licenserequest/${data.payment_id}`)
    },
    editLicense(data){
      this.plat_flag = true
      this.payment_id = data.payment_id
      this.showGrid = false
      this.isCreate = false
      this.$http.get(config.menu.host+'customer_org_pt/'+data.customer_org_id).then(function(res){
        var arr = []
        res.body.map(function(item,i){
          if((item.license_status != 'ACTIVE') && (item.license_status != 'APPROVED')){
            item['id']=i+1
            var transcoderName;
            if(item.platform_name == 'TRANSCODER'){
              item.customer_org_pt_kycs.map(function(arg){
                if(arg.kyc_key == 'transcoder_name'){
                  transcoderName = arg.kyc_value
                  item.platform_name = item.platform_name+"-"+transcoderName
                }
              })
            }
            arr.push({'id':item.id,'platform_name':item.platform_name,'license_status':item.license_status,'platform_id':item.platform_id,"transcoder_name":transcoderName,"license_id":item.license_id})
          }
        })
        this.license_request.platform.options = arr
      })
      for (const key in this.license_request) {
        if(key == 'customer_org_id') {
          this.license_request[key].value = this.license_request
          .customer_org_id.options.find(
            id => id.customer_org_id == data[key]
          )
          continue
        }
        this.license_request[key].value = data[key]
        if(key=='platform'){
          this.license_request.platform.value = JSON.parse(this.license_request.platform.value)
        }
      }
    },
    getRole(){
      return Vue.$cookies.get('userRole')
    },
    statusUpdate(status,payment){
      let payload = {
        status,
        customer_org_id:payment.customer_org_id
      }
      this.$http.put(config.menu.host+`payment/status/${payment.payment_id}`,payload)
      .then((res) => {
        Vue.notify({text: res.body,type: 'success'})
        payment.status = status
        this.resetValue()
        this.getPayments()
      })
    },
    getLabel(field){
      return field.required ? `${field.name} *` : field.name
    },
    exportData(){
      var rowKeys = {
        customer_name: 'customer organization',
        amount_paid: 'Amount Paid',
        reference_number: 'Reference Number',
        status: "Status"
      }
      return exportToFile(this.license_requests, rowKeys, 'Payment', 'CSV')
    },
    resetValue(){
      this.showGrid = true
      this.isCreate = true
      for (const key in this.license_request) {
        this.license_request[key].value = ""
        if(key == 'platform'){
          this.license_request[key].value = []
        }
      }
    },
    getPayments(){
      this.plat_flag = false
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host+'payment')
      .then(res => {
        loader.hide()
        this.license_requests = res.data
        this.isEnable = (this.license_requests.length > 0) ? true : false
        this.license_requests.map((user,i) => user.sno = i+1)
      })
      .catch(error => {
        loader.hide()
        Vue.notify({text: error.body,type: 'error'})
      })
    },
    getAllCustomer(){
      this.$http.get(config.menu.host+'customer_org').then(res => {
        res = res.data
        res.map((customer_org,i) => customer_org.id = ++i)
        this.license_request.customer_org_id.options = res
      })
    },
    addPayment(){
      let payload = {}
      for (const key in this.license_request) {
        let value = this.license_request[key].value
        if((this.license_request[key].name != 'comment') && (value == "")) {
          Vue.notify({text: "Please fill the form correctly",type: 'error'})
          return
        }
        payload[key] = value
      }
      let customer = payload.customer_org_id
      payload.customer_org_id = customer.customer_org_id
      payload.customer_name = customer.customer_org_name
      payload.platform.map(function(item){
        if(item.platform_name.includes("TRANSCODER")){
          item.platform_name = item.platform_name.split('-')[0]
        }
      })
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host+'payment',payload)
      .then(res => {
        loader.hide()
        Vue.notify({text:res.body,type: 'success'})
      })
      .catch(err => {
        loader.hide()
        Vue.notify({text: err.body,type: 'error'})
      }).then(()=> {
        this.resetValue()
        this.getPayments()
      })
    },
    updatePayment(){
      let payload = {}
      for (const key in this.license_request) {
        let value = this.license_request[key].value
        if((this.license_request[key].name != 'comment') && (value == "")) {
          Vue.notify({text: "Please fill the form correctly",type: 'error'})
          return
        }
        payload[key] = value
      }
      let customer = payload.customer_org_id
      payload.customer_org_id = customer.customer_org_id
      payload.customer_name = customer.customer_org_name
      payload.platform.map(function(item){
        if(item.platform_name.includes("TRANSCODER")){
          item.platform_name = item.platform_name.split('-')[0]
        }
      })
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host+`payment/${this.payment_id}`,payload)
      .then(res => {
        loader.hide()
        Vue.notify({text: res.body,type: 'success',})
      })
      .catch(err => {
        loader.hide()
        Vue.notify({text: err.body,type: 'error'})
      }).then(()=>{
        this.resetValue()
        this.getPayments()
      })
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  }
}
</script>

<style scoped>
a {
  color: royalblue;
}

a:hover {
  text-decoration: underline;
}

.badge {
  padding: 8px 0.5rem;
}

.status-true {
  color: green;
  padding-left: 1rem;
}

.status-false {
  color: red;
  padding-left: 1rem;
}

.comment {
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 10px;
  color: #2c82e0;
}

textarea {
  width: 100%;
}

</style>